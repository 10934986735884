import Header from "./Header";
import SearchEvent from "./SearchEvent";
import EventForm from "./EventForm";
import Content from "./Content";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import api from "./api/events";

function App() {
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState(''); 
  const [dateAct, setDateAct] = useState(''); 
  const [timeAct, setTimeAct] = useState(''); 
  const [activity, setActivity] = useState('');
  const [venue, setVenue] = useState('');
  const [responsible, setResponsible] = useState('');
  const [coordinated, setCoordinated] = useState('');
  const [presOfficer, setPresOfficer] = useState('');
  const [remarks, setRemarks] = useState('');

  useEffect(() => {
     const fetchEvents = async () => {
      try {
        const response = await api.get('/events');
        setEvents(response.data);
      } catch (err) {
        if (err.response) {
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
        } else {
          console.log(`Error: ${err.message}`);
      }
    }
  }

  fetchEvents();
  }, [])


  const addEvent = async () => {
    const id = events.length ? events[events.length - 1].id + 1 : 1;
    const time = `${timeAct}` === '--' ? "TBC" : timeAct;
    const newEvent = { id, done: false, date: dateAct, time: time, activity: activity, venue: venue, responsible: responsible, coordinated: coordinated, pres_officer: presOfficer, remarks: remarks };
    try {
        const response = await api.post(`/events`, newEvent);
        const allEvents =  [ ...events, response.data ];
        setEvents(allEvents);
    } catch (err) {
      console.log(`Error: ${err.message}`)
    }
  }

  const handleCheck = async (id) => {
    const listEvents = events.map((event) => event.id === id ? { ...event, done: !event.done } : event);
    setEvents(listEvents);

    const updateOptions = {id,  done: false, date: dateAct, time: timeAct, activity: activity, venue: venue, responsible: responsible, coordinated: coordinated, pres_officer: presOfficer, remarks: remarks};
    try {
      const result = await api.put(`/events/${id}`, updateOptions);
      setEvents(events.map((event) => event.id === id ? { ...result.data } : event));
      setSearch(''); 
      setDateAct(''); 
      setTimeAct(''); 
      setActivity('');
      setVenue('');
      setResponsible('');
      setCoordinated('');
      setPresOfficer('');
      setRemarks('');
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }  
  }

  const handleDelete = async (id) => {
    try {
      await api.delete(`/events/${id}`);
      const listEvents = events.filter((event) => event.id !== id);
      setEvents(listEvents);
    } catch (err) {
      console.log(`Error: ${err.message}`);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!activity) return;
    addEvent();
    setDateAct('');
    setTimeAct('');
    setActivity('');
    setVenue('');
    setResponsible('');
    setCoordinated('');
    setPresOfficer('');
    setRemarks('');
  }

  return (
    <div className="App">
      <Header title="MoGCDSW Events"/>
      <EventForm 
        dateAct={dateAct}
        setDateAct={setDateAct} 
        timeAct={timeAct} 
        setTimeAct={setTimeAct}
        activity={activity} 
        setActivity={setActivity}
        venue={venue}
        setVenue={setVenue}
        responsible={responsible}
        setResponsible={setResponsible}
        coordinated={coordinated}
        setCoordinated={setCoordinated}
        presOfficer={presOfficer}
        setPresOfficer={setPresOfficer}
        remarks={remarks} 
        setRemarks={setRemarks}
        handleSubmit={handleSubmit}
      />
      <SearchEvent
        search={search}
        setSearch={setSearch}
      />
      <main>
        {<Content
          events={events.filter(event => ((event.activity).toLowerCase()).includes(search.toLowerCase()))}
          handleCheck={handleCheck}
          handleDelete={handleDelete}
        />}
      </main>
      <Footer length={events.length}/>
    </div>
  );
}
export default App;

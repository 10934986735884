// EventForm.js
import React from 'react';
import { FaPlus } from "react-icons/fa";

const EventForm = ({ 
  dateAct, setDateAct, 
  timeAct, setTimeAct, 
  activity, setActivity, 
  venue, setVenue,
  responsible, setResponsible, 
  coordinated, setCoordinated, 
  presOfficer, setPresOfficer, 
  remarks, setRemarks, 
  handleSubmit 
}) => {
  return (
    <form className='eventForm' onSubmit={handleSubmit}>
        <label htmlFor="addevent">Add Event</label>
            <input
              autoFocus
              type="date"
              placeholder="Activity Date"
              value={dateAct}
              onChange={(e) => setDateAct(e.target.value)}
            />
            <input
              type="time"
              placeholder="Time"
              value={timeAct}
              onChange={(e) => setTimeAct(e.target.value)}
            />
            <input
              type="text"
              placeholder="Activity"
              value={activity}
              onChange={(e) => setActivity(e.target.value)}
            />
            <input
              type="text"
              placeholder="Venue"
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
            />
            <input
              type="text"
              placeholder="Responsible"
              value={responsible}
              onChange={(e) => setResponsible(e.target.value)}
            />
            <input
              type="text"
              placeholder="Coordinated"
              value={coordinated}
              onChange={(e) => setCoordinated(e.target.value)}
            /> 
            <input
              type="text"
              placeholder="Presiding Officer"
              value={presOfficer}
              onChange={(e) => setPresOfficer(e.target.value)}
            />
            <input
              type="text"
              placeholder="Remarks"
              value={remarks}
              onChange={(e) => setRemarks(e.target.value)}
            /> 
      <button 
            type="submit"
            aria-label="Add Event"
        >
            <FaPlus />
      </button>
    </form>
  );
};
export default EventForm;

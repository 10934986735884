// EventList.js
import LineEvent from './LineEvent'
import { FaTrashAlt } from 'react-icons/fa'

const EventList = ({ events, handleCheck, handleDelete }) => {
  return (
    <ul>
        {events.map((event) => (
           <LineEvent 
                key={event.id}
                event={event}
                handleCheck={handleCheck}
                handleDelete={handleDelete}
            />     
        ))}
    </ul>
  )
}
export default EventList;

// Content.js
import EventList from './EventList'

const Content = ({ events, handleCheck, handleDelete }) => {

  return (
    <>
      {events.length ? (
          <EventList
              events={events}
              handleCheck={handleCheck}
              handleDelete={handleDelete}
          />
          ) : ( <p style={{ marginTop: '2rem' }}>The list is empty. </p>
          )}
    </>
  )
}
export default Content;

// LineEvent.js
import { FaTrashAlt } from 'react-icons/fa'

const LineEvent = ({ event, handleCheck, handleDelete }) => {
  return (
    <li className="event">
        <input
            type="checkbox"
            onChange={() => handleCheck(event.id)}
            done={event.done}
        />
        <label
            style={(event.done) ? { textDecoration: 'line-through' } : null}
            onDoubleClick={() => handleCheck(event.id)}
            >{event.date}{', '}{event.time}{', '}{event.activity}{', '}{event.venue}{', '}
            {event.coordinated}{', '}{event.responsible}{', '}{event.pres_officer}{', '}{event.remarks}
        </label>
        <FaTrashAlt
            onClick={() => handleDelete(event.id)}
            role="button"
            tabIndex={0}
            aria-label={`Delete ${event.event}`}
        />
    </li>
  )
}
export default LineEvent;

// SearchEvent.js
import React from 'react'

const SearchEvent = ({ search, setSearch }) => {
  return (
    <form className='searchForm' onSubmit={(e) => e.preventDefault()}>
      <label htmlFor='search'>Search</label>
      <input 
        id='search'
        type='text'
        role='searchbox'
        placeholder='Search Events'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </form>
  )
}
export default SearchEvent;
